@tailwind base;
@tailwind components;
@tailwind utilities;

/* Set default here to override antd temporally */

html {
  @apply text-gray-700;
  
  font-family: YakuHanJP, Noto Sans JP, 游ゴシック Medium, Yu Gothic, YuGothic, ヒラギノ角ゴシック Pro, Hiragino Kaku Gothic Pro, メイリオ, Meiryo, Osaka, ＭＳ Ｐゴシック, MS PGothic, sans-serif;
  line-height: 1.6rem;
  text-align: justify;
}

a:hover {
  @apply text-gray-900;
}
