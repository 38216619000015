@import url("./variable.less");

body {
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: @global-bg-color;
  color: @global-color;
}

body,
#root {
  width: 100%;
  height: auto;
}

* {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

/*---滚动条默认显示样式--*/
::-webkit-scrollbar-thumb {
  height: 50px;
  outline-offset: -2px;
  outline: 2px solid #fff;
  -webkit-border-radius: 4px;
  border: 2px solid #fff;
}

/*---鼠标点击滚动条显示样式--*/
::-webkit-scrollbar-thumb:hover {
  height: 50px;
  -webkit-border-radius: 4px;
}

/*---滚动条大小--*/
::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

/*---滚动框背景样式--*/
::-webkit-scrollbar-track-piece {
  -webkit-border-radius: 0;
}

::-webkit-scrollbar-track {
  background-color: #eee;
}

/* 滚动条的滑轨背景颜色 */
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}

/* 滑块颜色 */
::-webkit-scrollbar-button {
  background-color: #eee;
}

/* 滑轨两头的监听按钮颜色 */
::-webkit-scrollbar-corner {
  background-color: black;
}

/* 横向滚动条和纵向滚动条相交处尖角的颜色 */
