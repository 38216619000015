.button-bar {
  display: flex;
  align-items: center;

  a {
    display: block;
    padding: 9px 109px;
    font-size: 16px;
    background-color: @btn-normal-bg-color;
    border: 1px solid @btn-normal-border-color;
    color: #bfbfbf;
    cursor: pointer;
    transition: 0.5s;

    &:hover {
      border-color: lighten(@tmr-color, 40%);
      color: lighten(@tmr-color, 40%);
    }

    &.full {
      background-color: #5b5b5b;
      border: none;
      color: #d4d4d4;
      font-weight: 800;

      &:hover {
        background-color: #2c2c2c;
        color: #c7c7c7;
        border-color: lighten(@tmr-color, 40%);
      }
    }
  }
}
