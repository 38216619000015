@import "../variable.less";
.g-item {
  position: relative;
  width: 293px;
  height: 385px;
  cursor: pointer;
  margin: 20px 5px 20px 5px;
  align-items: center;
  border: 1px solid rgb(229, 232, 235);
  border-radius: 6px;
  font-size: 14px;

  .g-item-mask {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 60%);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;

    span {
      color: #fff;
      font-size: 16px;
    }
  }

  .g-item-content {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &:hover {
    box-shadow: 0 0.3125rem 0.75rem -10px rgb(0 0 0 / 70%);
  }
  .heart {
    position: absolute;
    width: 22px;
    height: 28px;
    top: 5px;
    right: 5px;
    font-size: 18px;
    color: white;
    &:hover {
      color: red;
    }
  }
  p {
    font-style: normal;
    font-weight: 500;
    line-height: 1.2;
    align-items: center;
    letter-spacing: 0.02em;
    color: #000;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    margin: 10px 0 10px;
    font-size: 12px;
  }
  &-ft {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
    justify-content: flex-end;
    align-items: center;

    .price-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      line-height: 1;

      .list-item {
        width: 70%;
        margin: 0 30%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-size: 16px;

        &.show-filter {
          width: 75%;
          margin: 0 25%;
        }
        &.hide-filter {
          width: 70%;
          margin: 0 30%;
        }

        .value {
          color: #000;
          font-weight: 800;
          margin: 0;
          width: 25%;

          span {
            font-size: 16px;
            font-weight: normal;
            color: rgb(112, 122, 131);
            align-items: flex-start;
          }
        }

        .label {
          margin: 2px 0;
          width: 50%;
          font-weight: 500;
          color: #000;
        }
      }
    }
  }
  &-home-img {
    width: 100%;
    height: calc(100% - 80px);

    img {
      height: 100%;
      width: 100%;
      border-radius: 6px;
      object-fit: cover;
    }
  }

  .gridimg {
    width: 100%;
    height: calc(100% - 80px);
    img {
      height: 100%;
      width: 100%;
      border-radius: 6px;
      object-fit: cover;
    }
  }
  &-img {
    width: 100%;
    height: calc(100% - 80px);
    border-radius: 6px;
    img {
      height: 100%;
      width: 100%;
      border-radius: 6px;
      object-fit: cover;
    }

    &-value {
      display: inline-block;
      color: #222222;
      font-family: Shapiro, sans-serif;
      font-weight: 800;
      font-size: 16px;
      line-height: 1;
    }
    &-buy {
      display: inline-block;
      cursor: pointer;
    }
    .play-controls {
      position: absolute;
      bottom: 30%;
      right: 20px;
      font-size: 30px;
      color: #fff;
      & video {
        object-fit: cover;
      }
    }
    video {
      object-fit: cover;
    }
  }
}
.marketimg {
  width: 100%;
  height: calc(100% - 80px);
  img {
    height: 100%;
    width: 100%;
    border-radius: 6px;
    object-fit: cover;
  }
}
.anticon-wallet {
  color: white;
  cursor: pointer;
  margin-left: 5px;
}
